import { useNavigate } from 'react-router-dom'
import { Dropdown, DropdownItem } from '@cb/apricot-react'

import FilterDropdown from '@/components/ui/dropdowns/FilterDropdown'
import { useDistrictUserState } from '@/hooks/useProfessionalBaseData'
import * as gqlCache from '@/services/graphql/professionals/cache'
import { GET_CACHED_COMMON_DATA, GET_ORG_HIERARCHY, GET_ORGS_BY_ID } from '@/services/graphql/professionals/queries'
import { useCustomQuery } from '@/services/graphql/utils'
import { PROF_LINKS } from '@/utils/professionals'
import { getAllowedOrgs } from '@/utils/user'

import './org-navigation.scss'

const MAX_VISIBLE_ITEMS = 11

const SecondaryNavigation = ({ eduPeriods }) => {
  const navigate = useNavigate()
  const { isDistrictUser } = useDistrictUserState()
  const {
    data: { currentEduPeriod, selectedOrg, selectedSchool },
  } = useCustomQuery(GET_CACHED_COMMON_DATA)
  const { data: topLevelOrgs } = useCustomQuery(GET_ORGS_BY_ID, {
    variables: { orgIds: getAllowedOrgs() },
  })
  const { data: subLevelOrgs } = useCustomQuery(GET_ORG_HIERARCHY, {
    variables: { orgId: selectedOrg.orgId },
    skip: !isDistrictUser,
  })

  const onSelectEdPeriod = (edPeriod) => {
    gqlCache.currentEduPeriodVar(edPeriod)
    navigate(PROF_LINKS.dashboard)
  }

  const onSelectOrg = (org) => {
    gqlCache.selectedOrgVar(org)
    gqlCache.selectedSchoolVar(null)
    navigate(PROF_LINKS.dashboard)
  }

  const onSelectSchool = (schoolOrg) => {
    gqlCache.selectedSchoolVar(schoolOrg)
    navigate(PROF_LINKS.dashboard)
  }

  const renderOrgSelector = () => {
    const items = (topLevelOrgs || []).filter((e) => e.orgId !== selectedOrg.orgId)
    if ((topLevelOrgs || []).length > 1) {
      return (
        <Dropdown title={selectedOrg.orgName} closeOnClick className="cb-font-weight-medium" key={selectedOrg.orgName}>
          {items.map((item) => (
            <DropdownItem key={item.orgId} label={item.orgName} onClick={() => onSelectOrg(item)} />
          ))}
        </Dropdown>
      )
    }
    return <div className="cb-font-size-small cb-font-weight-medium">{selectedOrg.orgName}</div>
  }

  const renderSchoolSelector = () => {
    const orgInTitle = selectedSchool || selectedOrg
    const pinnedItem = {
      id: selectedOrg.orgId,
      label: selectedOrg.orgName,
      selected: orgInTitle.orgId === selectedOrg.orgId,
      onClick: () => onSelectOrg(selectedOrg),
      icon: { name: 'home', color: 'black1' },
    }
    const items = (subLevelOrgs || []).map((org) => ({
      id: org.orgId,
      label: org.orgName,
      selected: orgInTitle.orgId === org.orgId,
      onClick: () => onSelectSchool(org),
    }))

    return (
      <FilterDropdown
        title={orgInTitle.orgName}
        pinnedItem={pinnedItem}
        items={items}
        maxVisibleItems={MAX_VISIBLE_ITEMS}
      />
    )
  }

  const renderYearSelector = () => {
    if (eduPeriods.length > 1) {
      const items = eduPeriods.filter((e) => e.code !== currentEduPeriod.code)
      return (
        <Dropdown
          title={currentEduPeriod.descr}
          closeOnClick
          className="cb-font-weight-medium cb-margin-left-24"
          key={currentEduPeriod.code}
        >
          {items.map((item) => (
            <DropdownItem key={item.code} label={item.descr} onClick={() => onSelectEdPeriod(item)} />
          ))}
        </Dropdown>
      )
    }
    return <div className="cb-font-size-small cb-font-weight-medium cb-margin-left-24">{currentEduPeriod.descr}</div>
  }

  return (
    <div className="cb-border-bottom clep-org-nav">
      <div className="container display-flex align-items-center">
        {!isDistrictUser && renderOrgSelector()}
        {isDistrictUser && renderSchoolSelector()}
        {renderYearSelector()}
      </div>
    </div>
  )
}

export default SecondaryNavigation

SecondaryNavigation.propTypes = {
  eduPeriods: PropTypes.array.isRequired,
}
