import { addToDate, formatDateInTimeZone } from '@clep/utilities/helpers'
import classnames from 'classnames'
import get from 'lodash/get'
import pick from 'lodash/pick'

import BannerFallback from '@/components/ui/fallbacks/BannerFallback'
import {
  COLUMN_OFFSET,
  COLUMN_SIZE_FORMS,
  COUNTRY_CODE,
  COUNTRY_OPTIONS,
  PROF_REQUEST_TYPE,
  US_DIAL_COUNTRY_CODES,
} from '@/utils/constants'
import { getProfile } from '@/utils/user'
import { contactFormValidator } from '@/components/professionals/org-profile-form/validation'
import { saveOrgErrorMessage, saveOrgQueryErrorMessage } from './messages'

/**
 * NAVIGATION
 */
export const PROF_LINKS = {
  dashboard: '/dashboard',
  noaccess: '/accessing-clep/no-access',
  accessrequestform: '/accessing-clep/request-form',
  accessrequestconfirm: '/accessing-clep/request-confirm',
  pfterms: '/terms-and-conditions',
  orgprofilesetup: '/org-profile/setup',
  orgprofileconfirm: '/org-profile/confirm',
  orgprofileview: '/org-profile/view',
  orgprofileedit: '/org-profile/edit',
  roster: '/roster',
  scores: '/scores',
  billing: '/billing',
  settings: '/settings',
  orderintro: '/orders/intro',
  orderform: '/orders/form',
  orderreview: '/orders/review',
  orderterms: '/orders/terms',
  orderconfirm: '/orders/confirm',
}

export const PROF_DASHBOARD_PANELS = {
  summary: 'summary',
  orders: 'order-history',
}

export const PROF_MAIN_NAVIGATION = [{ path: PROF_LINKS.dashboard, title: 'Home', icon: 'home', neverHideLink: true }]

export const PROF_FLOWS_ORDERS = [
  PROF_LINKS.orderintro,
  PROF_LINKS.orderform,
  PROF_LINKS.orderreview,
  PROF_LINKS.orderterms,
  PROF_LINKS.orderconfirm,
]

export const PROF_ORDER_STEPS = [
  { title: 'Orders', subtitle: 'Ordering CLEP Vouchers' },
  { title: 'Order Details', subtitle: 'Ordering CLEP Vouchers' },
  { title: 'Order Review', subtitle: 'Ordering CLEP Vouchers' },
  { title: 'Order Review', subtitle: 'Ordering CLEP Vouchers' },
  { title: 'Order Confirmation', subtitle: 'Ordering CLEP Vouchers' },
  { title: 'Update Order Details', subtitle: 'Updating CLEP Vouchers' },
  { title: 'Updated Order Review', subtitle: 'Updating CLEP Vouchers' },
  { title: 'Updated Order Confirmation', subtitle: 'Updating CLEP Vouchers' },
]

const getFallback = (path) => {
  const index = PROF_FLOWS_ORDERS.findIndex((p) => p === path)
  return (
    <BannerFallback {...(PROF_ORDER_STEPS[index] || {})} columnSize={classnames(COLUMN_OFFSET, COLUMN_SIZE_FORMS)} />
  )
}

const BASE_PATH = 'professionals'

export const PROF_ROUTES = {
  dashboard: { filepath: `${BASE_PATH}/dashboard/Dashboard` },
  noaccess: { filepath: `${BASE_PATH}/access-missing/AccessCode`, hideNavigation: true },
  accessrequestform: { filepath: `${BASE_PATH}/access-request-form/AccessRequestForm`, hideNavigation: true },
  accessrequestconfirm: { filepath: `${BASE_PATH}/access-request-confirm/AccessRequestConfirm`, hideNavigation: true },
  pfterms: { filepath: `${BASE_PATH}/pf-terms/TermsAndConditions`, hideNavigation: true },
  orgprofilesetup: { filepath: `${BASE_PATH}/org-profile-setup/OrgProfileSetup` },
  orgprofileconfirm: { filepath: `${BASE_PATH}/org-profile-confirm/OrgProfileConfirm` },
  orgprofileview: { filepath: `${BASE_PATH}/org-profile-view/OrgProfileView` },
  orgprofileedit: { filepath: `${BASE_PATH}/org-profile-edit/OrgProfileEdit` },
  roster: { filepath: undefined },
  scores: { filepath: undefined },
  billing: { filepath: undefined },
  settings: { filepath: undefined },
  orderintro: {
    filepath: `${BASE_PATH}/order-intro/OrderIntro`,
    hideNavigation: true,
    fallback: getFallback,
  },
  orderform: {
    filepath: `${BASE_PATH}/order-form/OrderForm`,
    hideNavigation: true,
    fallback: getFallback,
  },
  orderreview: {
    filepath: `${BASE_PATH}/order-review/OrderReview`,
    hideNavigation: true,
    fallback: getFallback,
  },
  orderterms: {
    filepath: `${BASE_PATH}/order-terms/OrderTerms`,
    hideNavigation: true,
    fallback: getFallback,
  },
  orderconfirm: {
    filepath: `${BASE_PATH}/order-confirm/OrderConfirm`,
    hideNavigation: true,
    fallback: getFallback,
  },
}
/**
 * end NAVIGATION
 */

/**
 * FORMATTERS
 */
export const formatRequestAccessPayload = (formData) => {
  const { firstName, lastName } = getProfile()

  let overrides = {}
  if (!formData.cantFindOrg) {
    const orgSelected = formData.orgSelected || {}
    overrides = {
      ...pick(formData, ['orgWebsite', 'title', 'department', 'phone']),
      orgId: orgSelected.orgId,
      orgName: orgSelected.name,
      orgAddress: orgSelected.address,
    }
  } else {
    overrides = pick(
      formData,
      [
        'orgName',
        'orgType',
        'accreditationOrg',
        'accreditationLink',
        'primaryAddress',
        'orgWebsite',
        'title',
        'department',
        'phone',
      ].concat(formData.diffMailingAddressInd === 'Y' ? ['mailingAddress'] : [])
    )
  }

  return {
    firstName,
    lastName,
    requestType: PROF_REQUEST_TYPE.ACCESS,
    accessReqReasonCd: formData.accessReqReasonCd,
    ...overrides,
  }
}

const formatContact = (contact = {}) => {
  const pickKeys = ['firstName', 'lastName', 'title', 'email'].filter((key) => !!contact[key]) // get keys where contact[key] has a value
  const pickedObject = pick(contact, pickKeys)
  return {
    ...pickedObject,
    ...(contact?.phone && { phone: `+${(contact?.phone || '').replace(/\D/g, '')}` }),
  }
}

export const formatOrgProfilePayload = (formData, educationPeriodCd) => ({
  educationPeriodCd, // required
  coordinator: formatContact(formData.coordinator), // required
  billing: formatContact(formData.isBillingInfoSame ? formData.coordinator : formData.billing), // required
  principal: formData.principal ? formatContact(formData.principal) : undefined,
  alternateContact: !contactFormValidator(formData.alternateContact, false)
    ? formatContact(formData.alternateContact || {})
    : undefined,
})

export const formatOrderPayload = ({ orderForm, currentEduPeriod }) => {
  const {
    adminWindowCd,
    quantity,
    expirationDate,
    poNumber,
    includeProctorFees,
    fundingTypeCd,
    fundingComment,
    suppressScores,
    isEditFlow,
    orderId,
  } = orderForm

  return {
    ...(isEditFlow && { orderId }),
    educationPeriodCd: currentEduPeriod.code,
    ...(poNumber && { purchaseOrderNumber: poNumber }),
    adminWindowCd,
    quantity,
    ...(expirationDate && { expirationDate: new Date(expirationDate).toISOString() }),
    ...(includeProctorFees && { proctorFeeCoveredInd: includeProctorFees === 'Y' }),
    ...(fundingTypeCd && {
      fundingTypeCd,
      fundingComment: fundingComment || '',
      scoreSendsAllowedInd: suppressScores === 'N',
    }),
  }
}

export const getExpirationDateRange = (adminWindow) => {
  const nextDay = addToDate({ date: new Date(), duration: { days: 1 } })
  return {
    start: formatDateInTimeZone({ date: nextDay }),
    end: adminWindow?.voucherExpirationDate,
  }
}

export const getOrgTypeName = (org) => {
  if (org?.highSchoolInd) return 'school'
  if (org?.districtInd) return 'district'
  return 'organization'
}

export const formatAddress = (address) => {
  const { countryCd, city, stateCd, postalCd, province } = address || {}

  if (!countryCd) return ''
  if (countryCd === COUNTRY_CODE.US) {
    return city && stateCd && postalCd ? `${city}, ${stateCd} ${postalCd}` : ''
  }

  const countryName = COUNTRY_OPTIONS.find((e) => e.value === countryCd)?.label
  if (city && countryName) {
    return province || postalCd
      ? `${city}, ${[province, postalCd].filter(Boolean).join(' ')} ${countryName}`
      : `${city}, ${countryName}`
  }
  return ''
}

/**
 * end FORMATTERS
 */

export const areOrgContactsEqual = (c1, c2) =>
  ['firstName', 'lastName', 'email', 'phone'].every((field) => get(c1, field) === get(c2, field))

/**
 * Extracts and returns a customized error message from the result of a saveOrgQuery.
 * This query is the result of  useMutation(MUTATION_SAVE_ORG_PROFILE)
 *
 * @param {Object} queryError - The error object returned from the saveOrgQuery.
 * @returns {string|Object} - The customized error message or the original queryError.
 * @example
 * // Usage:
 * const queryError = {
 *   error: {
 *     message: "Invalid Phone Number"
 *   }
 * };
 * const errorMessage = extractErrorMessageFromSaveOrgQuery(queryError);
 * // errorMessage will be "Invalid Phone Number"
 */
export const extractErrorMessageFromSaveOrgQuery = (queryError) => {
  const errMessage = `${queryError?.message}`.toLowerCase()

  if (errMessage.includes(saveOrgQueryErrorMessage.invalidPhone)) {
    return saveOrgErrorMessage.invalidPhone
  }

  return queryError
}

export const hasUsDialCode = (address) => US_DIAL_COUNTRY_CODES.includes(address?.countryCd?.toLowerCase())
