import classnames from 'classnames'
import PropTypes from 'prop-types'

import loadFailedIcon from '@/assets/images/clep-load-failed.svg'
import noInfoIcon from '@/assets/images/clep-no-info.svg'
import { ERROR_ICON_TYPES } from '@/utils/constants'

const ERROR_ICONS = {
  [ERROR_ICON_TYPES.LOAD_FAILED]: loadFailedIcon,
  [ERROR_ICON_TYPES.NO_INFO]: noInfoIcon,
}

const Error = ({ message, title, iconType = ERROR_ICON_TYPES.LOAD_FAILED, wrapperClass = 'col-xs-12', errorClass }) => {
  if (title)
    return (
      <div className="container">
        <div className="row">
          <div className={wrapperClass}>
            <div className="cb-align-center">
              <div className="cb-padding-bottom-8">
                <img src={ERROR_ICONS[iconType]} alt="Clep Error" className="cb-img-fluid" width={200} />
              </div>
              <h2 className="cb-h2 cb-margin-top-48">{title}</h2>
              <p className="cb-font-size-regular cb-margin-top-16">{message}</p>
            </div>
          </div>
        </div>
      </div>
    )

  if (message) return <p className={classnames(errorClass, 'cb-red1-color')}>{message}</p>

  return null
}

Error.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  iconType: PropTypes.string,
  wrapperClass: PropTypes.string,
  errorClass: PropTypes.string,
}

export default Error
