import { formatDay, validateString } from '@clep/utilities/helpers'
import { ADMIN_TYPE_INSCHOOL, PHONE_NUMBER_LENGTH, QUERY_PARAM_TAB } from './constants'

export const isMobile = (viewport) => viewport === 'xs'
export const isSmallDevice = (viewport) => isMobile(viewport) || viewport === 'sm'
export const isLargeDevice = (viewport) =>
  viewport === 'md' || viewport === 'lg' || viewport === 'xl' || viewport === '2xl' || viewport === '3xl'

export const generateTabLink = (dashboardLink, tabId) => `${dashboardLink}?${QUERY_PARAM_TAB}=${tabId}`
export const generateRegistrationLink = (link, regId) => link.replace(':registrationId', regId)

export const formatNumber = (value) => Number(value).toLocaleString('en-US')
export const formatDollar = (value, digits = 0) =>
  Number.isNaN(parseFloat(value))
    ? value
    : Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })
export const formatURL = (path, key, value) => path.replace(`:${key}`, value)
export const formatExpirationDate = (date) => (date ? formatDay({ date, format: "MMMM dd, yyyy hh:mm aaaa 'ET'" }) : '')

export const formatPhone = (phone) => {
  const match = String(phone).match(/^(\d{3})(\d{3})(\d{4})$/)
  return match && `(${match[1]}) ${match[2]}-${match[3]}`
}

export const formatIntlPhone = (phone) => {
  if (!phone) return ''

  const onlyDigits = (phone || '').replace(/\D/g, '')
  const match = onlyDigits.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match && onlyDigits.startsWith('1')) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return `+${phone}`
}

export const formatGender = (genderCd) => ({ M: 'Male', F: 'Female' }[genderCd] || 'Another')

export const capitalizePhrase = (str, splitter = ' ') =>
  str
    .split(splitter)
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ')

export const isVoucherInSchool = (voucher = {}, adminWindows = []) => {
  const matchingWnd = adminWindows.find(
    (wnd) => voucher.adminWindowCd && wnd.adminWindowCd && wnd.adminWindowCd === voucher?.adminWindowCd
  )

  return matchingWnd && matchingWnd.adminType === ADMIN_TYPE_INSCHOOL
}

export const VOUCHER_MIN_LEN = 4
export const VOUCHER_MAX_LEN = 8
export const isVoucherValidFormat = (str) =>
  validateString({ str, minLength: VOUCHER_MIN_LEN, maxLength: VOUCHER_MAX_LEN })

export const arrayContains = (arr = [], matchers = []) => Boolean(matchers.find((n) => arr?.includes(n)))

const isSsnInputValid = (str = '') => /^\d{3}-\d{2}-\d{4}$/.test(str)
const isSsnRedacted = (str = '') => /^\*\*\*\*\*[0-9]{4}$/.test(str) // API returns SSN in "*****1234" format
export const isSsnValid = (str = '') => isSsnInputValid(str) || isSsnRedacted(str)

export const isPhoneInputValid = (str) => {
  const onlyDigits = (str || '').replace(/\D/g, '')
  if (onlyDigits.startsWith('1')) {
    // US
    return onlyDigits.length === PHONE_NUMBER_LENGTH.US
  }
  return onlyDigits.length > 0 && onlyDigits.length <= PHONE_NUMBER_LENGTH.NON_US_MAX
}

export const isUrlValid = (str = '') =>
  /^.{1,255}$/.test(str) &&
  /^https?:\/\/(www\.)?[-a-zA-Z0-9:._]{2,256}\.[a-zA-Z0-9]{2,6}\b([-a-zA-Z0-9;,/?:@&=+$_.!~*'()#]*)$/.test(str)

export const isEmailValid = (str = '') =>
  /^.{1,50}$/.test(str) && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(str)

export const triggerDownload = (blobUrl, fullFileName) => {
  const link = document.createElement('a')
  link.href = blobUrl
  link.setAttribute('download', fullFileName)
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export const isCustomDICode = (diCode) => diCode?.toString().startsWith('Custom DI')

export const getCollegeNameFromDICode = (colleges, diCode) =>
  isCustomDICode(diCode)
    ? 'Score Recipient Entered By Test Taker'
    : (colleges || []).find((c) => c.code === diCode)?.name
