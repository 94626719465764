import { gql } from '@apollo/client'

// Get common data from local cache
export const GET_CACHED_COMMON_DATA = gql`
  query getCachedCommonData {
    commonData @client {
      currentEduPeriod
    }
  }
`

// Get registration form details from local cache
export const GET_CACHED_REGISTRATION_FORM = gql`
  query getCacheRegistrationForm {
    registrationForms @client
  }
`

// Get profile form details from local cache
export const GET_CACHED_PROFILE_FORM = gql`
  query getCacheProfileForm {
    profileForm @client
  }
`

// Get organizations data by aiCode
export const GET_ORGS = gql`
  query getOrgs($aiCodes: [String!]) {
    getOrgs(input: { ais: $aiCodes }) {
      aiCode
      orgId
      orgName
      highSchoolInd
      districtInd
    }
  }
`

export const GET_ACCOMMODATIONS = gql`
  query getAccommodations {
    getAccommodations {
      accommodations {
        accommodationName
        accommodationCd
        categoryName
        categoryCd
        supplementalText
      }
    }
  }
`

export const GET_STUDENT_PROFILE = gql`
  query getStudentProfile {
    getStudentProfile {
      firstName
      lastName
      middleInitial
      email
      genderCd
      birthDt
      hsGradDt
      attendingAiCode
      homeSchoolInd
      clgCd
      clgEdLvlCd
      highestEdLvlCd
      address {
        streetAddr1
        streetAddr2
        city
        countryCd
        stateCd
        postalCd
        province
      }
      phone {
        number
      }
      dantes {
        categoryCd
        civilianCd
        branchCd
        statusCd
        paygradeCd
        myCaaNum
        faNum
        ssn
      }
      ethnicities
      races
      bestLanguageCd
      firstLanguageCd
      derivedFields @client
    }
  }
`

export const GET_VOUCHER_BY_CODE = gql`
  query getVoucher($voucherId: String!) {
    getVoucher(input: { voucherId: $voucherId }) {
      voucherId
      status
      expirationDate
      adminWindowCd
      scoreSendsAllowedInd
      isActive @client
    }
  }
`

export const getVouchersByCodesQuery = (voucherIds) => {
  const voucherQueries = (voucherIds || [])
    .map(
      (id, index) => `
    voucher${index}: getVoucher(input: { voucherId: "${id}" }) {
      voucherId
      status
      expirationDate
      adminWindowCd
      isActive @client
    }
  `
    )
    .join('\n')

  // `toBeSkipped` is a made-up keyword just so the query is valid,
  // and to indicate that we need to skip this query if voucherQueries.length = 0
  // when calling useQuery
  return gql`
    query getVouchers {
      ${voucherQueries?.length > 0 ? voucherQueries : 'toBeSkipped'}
    }
  `
}

export const GET_ALLOWED_DATA_COLLECTIONS = gql`
  query getAllowedDataCollections {
    getAllowedDataCollections {
      ethnicity
      race
    }
  }
`

// fetch all exams with orderable flag
export const GET_ORDERABLE_EXAMS = gql`
  query getOrderableExams {
    getOrderableExams {
      code
      orderable
      takenDate
      retakeAllowDate
    }
  }
`

export const GET_DANTES_ELIGIBLE_EXAMS = gql`
  query getDantesEligibleExams {
    getDantesEligibleExams
  }
`

export const GET_DANTES_ELIGIBLE_STUDY_MATERIALS = gql`
  query getDantesEligibleStudyMaterials {
    getDantesEligibleStudyMaterials
  }
`

export const GET_ORDER_PRICING = gql`
  query getOrderPricing($input: OrderPriceInput!) {
    getOrderPricing(input: $input) {
      payments {
        subtotal
        tax
        totalAmount
        products {
          code
          cost
          lineItems {
            code
            productType
            originalCost
            actualCost
            quantity
            voucherId
            dantesFundedInd
            remoteProctoringInd
          }
        }
      }
      refunds {
        subtotal
        tax
        totalAmount
        products {
          code
          lineItems {
            code
            productType
            originalCost
            actualCost
            quantity
            voucherId
            dantesFundedInd
            remoteProctoringInd
            refundAmount
          }
        }
      }
    }
  }
`
export const GET_ORDER = gql`
  query getOrder($orderId: String!) {
    getOrder(input: { orderId: $orderId }) {
      orderId
      ecomOrderId
      orgId
      status
      createdDate
      products {
        code
        voucherId
        dantesFundedInd
        remoteProctoringInd
        accommodations
        diCodes
        cancelInd
        expirationDate
        cancellationDate
      }
      paymentUrl
    }
  }
`

export const GET_TRANSCRIPT_ORDER = gql`
  query getTranscriptOrderById($orderId: String!) {
    getTranscriptOrderById(input: { orderId: $orderId }) {
      orderId
      ecomOrderId
      status
      createdDate
      products {
        code
        quantity
      }
      sendScores {
        registrationId
        diCode
      }
    }
  }
`

export const GET_ORDER_BY_ECOM_ID = gql`
  query getOrder($ecomOrderId: String!) {
    getOrder(input: { ecomOrderId: $ecomOrderId }) {
      orderId
      ecomOrderId
      createdDate
      ecomData {
        payment {
          total
          cardType
          last4Digits
        }
        refunds {
          productCode
          refundAmount
        }
      }
    }
  }
`

export const DOWNLOAD_EGUIDE_ORDER = gql`
  query downloadEguide($input: DownloadEguideInput) {
    downloadEguide(input: $input) {
      url
    }
  }
`

export const GET_TRANSCRIPT_ORDERS = gql`
  query getTranscriptOrders($input: GetTranscriptOrdersInput) {
    getTranscriptOrders(input: $input) {
      orderId
      ecomOrderId
      status
      createdDate
      products {
        code
        quantity
        cancelInd
      }
      sendScores {
        diCode
      }
    }
  }
`

export const GET_UPDATEABLE_PRODUCTS = gql`
  query getUpdateableProducts($orderId: String!) {
    getUpdateableProducts(input: { orderId: $orderId }) {
      productCode
      cancelable
      updateable
    }
  }
`

export const GET_ENABLED_FEATURES = gql`
  query getEnabledFeatures {
    getEnabledFeatures {
      features
    }
  }
`
export const GET_REG_EGUIDE_ORDERS = gql`
  query getRegEguideOrders {
    getRegEguideOrders {
      orderId
      ecomOrderId
      status
      createdDate
      products {
        code
        cancelInd
        cancellationDate
        expirationDate
      }
    }
  }
`

export const GET_REGISTRATIONS = gql`
  query getRegistrations($input: GetRegistrationsInput) {
    getRegistrations(input: $input) {
      registrationId
      orderId
      productCd
      dantesFundedInd
      status
      expirationDate
      adminWindowCd
      accommodations
      remoteProctoringInd
      voucherId
      statusUpdatedDate
      sendScores {
        diCode
        status
        statusUpdatedDate
        createdDate
      }
    }
  }
`

export const GET_SCORES = gql`
  query getScores($input: GetScoresInput) {
    getScores(input: $input) {
      registrationId
      catapultId
      orgId
      educationPeriodCd
      score
      status
      viewable
      createdDate
      updatedDate
    }
  }
`
