import classnames from 'classnames'
import BannerFallback from '../components/ui/fallbacks/BannerFallback'
import {
  COLUMN_OFFSET,
  COLUMN_SIZE_FORMS,
  MESSAGE_TYPE,
  PRICING_TYPE,
  PRODUCT_CODE_PROCTOR,
  PRODUCT_CODE_TRANSCRIPT,
  PRODUCT_TYPE_EXAM,
  PRODUCT_TYPE_PDF,
} from './constants'

const BASE_PATH = 'students'

/**
 * NAVIGATION
 */
export const STUDENT_BASE_LINKS = {
  dashboard: 'dashboard',
  profilereview: 'personal-information/review',
  profileeducation: 'personal-information/education',
  profiledantes: 'personal-information/dantes',
  profiledemographics: 'personal-information/demographics',
  sendscoreselection: 'send-score/score-recipients',
  examintro: 'select-exam/overview',
  profileintro: 'personal-information/overview',
  sendscoreintro: 'send-score/overview',
  ordercheckoutintro: 'checkout/overview',
  examterms: 'terms-and-conditions',
  examvoucher: 'exam-voucher',
  examselection: 'clep-exam',
  examlocationselection: 'testing-location',
  examaccommodationselection: 'exam-accommodations',
  examstudymaterialselection: 'study-material',
  examaddanother: 'add-another-exam',
  orderreview: 'checkout/review',
  orderconfirm: 'checkout/confirm',
  orderprocesspayment: 'checkout/payment',
  ordercomplete: 'checkout/complete',
  registrationdetails: 'registration/:registrationId/details',
  transcriptcreate: 'transcript',
  transcriptselectscores: 'select-scores',
  transcriptcheckoutreview: 'checkout/review',
  transcriptterms: 'checkout/terms',
  transcriptpayment: 'checkout/payment',
  transcriptconfirm: 'checkout/confirm',
  studymaterial: 'studymaterial',
  studymaterialexams: 'select-exams',
  studymaterialeguides: 'select-eguides',
  studymaterialreview: 'checkout/review',
  studymaterialterms: 'checkout/terms',
  studymaterialpayment: 'checkout/payment',
  studymaterialcomplete: 'checkout/complete',
  registrationcreate: 'registration',
  registrationupdate: 'registration/:registrationId/update',
  registration: 'registration/:registrationId',
  registrationcancelnoselection: '/order/:orderId/cancel',
  registrationcancel: '/order/:orderId/cancel/:productCd',
  registrationcancelreminders: '/order/:orderId/cancel/reminders',
  registrationcancelreminderseguide: '/order/:orderId/cancel/reminders-eguide',
  registrationcancelrevieworder: '/order/:orderId/cancel/review',
  registrationcancelconfirm: '/order/:orderId/cancel/confirm',
  orderdetails: '/order/:orderType/:orderId/details',
}

export const STUDENT_FILEPATHS = {
  dashboard: `${BASE_PATH}/dashboard/Dashboard`,
  registrationcreate: `${BASE_PATH}/registration-flows/CreateRegistrationFlow`,
  registrationupdate: `${BASE_PATH}/registration-flows/UpdateRegistrationFlow`,
  profileintro: `${BASE_PATH}/register-intro-profile/RegisterIntroProfile`,
  profilereview: `${BASE_PATH}/register-profile-review/RegisterProfileReview`,
  profileeducation: `${BASE_PATH}/register-profile-education/RegisterProfileEducation`,
  profiledantes: `${BASE_PATH}/register-profile-dantes/RegisterProfileDantes`,
  profiledemographics: `${BASE_PATH}/register-profile-demographics/RegisterProfileDemographics`,
  examstudymaterialselection: `${BASE_PATH}/register-study-material/RegisterStudyMaterial`,
  examintro: `${BASE_PATH}/register-intro-exam/RegisterIntroExam`,
  examvoucher: `${BASE_PATH}/register-exam-voucher/RegisterExamVoucher`,
  examselection: `${BASE_PATH}/register-exam-selection/RegisterExamSelection`,
  examlocationselection: `${BASE_PATH}/register-exam-location/RegisterExamLocation`,
  examaccommodationselection: `${BASE_PATH}/register-accommodations/RegisterAccommodations`,
  examaddanother: `${BASE_PATH}/register-add-another/RegisterAddAnother`,
  sendscoreintro: `${BASE_PATH}/register-intro-send-scores/RegisterIntroSendScores`,
  sendscoreselection: `${BASE_PATH}/register-send-scores/RegisterSendScores`,
  ordercheckoutintro: `${BASE_PATH}/register-intro-checkout/RegisterIntroCheckout`,
  examterms: `${BASE_PATH}/register-terms/RegisterTerms`,
  orderreview: `${BASE_PATH}/register-order-review/RegisterOrderReview`,
  orderconfirm: `${BASE_PATH}/register-order-confirm/RegisterOrderConfirm`,
  orderprocesspayment: `${BASE_PATH}/payment-processing/PaymentProcessing`,
  ordercomplete: `${BASE_PATH}/register-order-complete/RegisterOrderComplete`,
  registrationdetails: `${BASE_PATH}/registration-details/RegistrationDetails`,
  registrationcancel: `${BASE_PATH}/registration-cancel/RegistrationCancel`,
  registrationcancelnoselection: `${BASE_PATH}/registration-cancel/RegistrationCancel`,
  registrationcancelrevieworder: `${BASE_PATH}/registration-cancel-review-order/RegistrationCancelReviewOrder`,
  registrationcancelconfirm: `${BASE_PATH}/registration-cancel-confirm/RegistrationCancelConfirm`,
  registrationsendscoreupdate: `${BASE_PATH}/registration-flows/SendScoresUpdateFlow`,
  registrationaccommodationsupdate: `${BASE_PATH}/registration-flows/AccommodationsUpdateFlow`,
  registrationcancelreminders: `${BASE_PATH}/registration-cancel-reminders/RegistrationCancelReminders`,
  registrationcancelreminderseguide: `${BASE_PATH}/registration-cancel-reminders-eguide/RegistrationCancelRemindersEguide`,
  transcriptcreate: `${BASE_PATH}/request-transcript-flow/RequestTranscriptFlow`,
  transcriptselectscores: `${BASE_PATH}/request-transcript/RequestTranscript`,
  transcriptcheckoutreview: `${BASE_PATH}/request-transcript-checkout-review/RequestTranscriptCheckoutReview`,
  transcriptterms: `${BASE_PATH}/request-transcript-terms/RequestTranscriptTerms`,
  transcriptpayment: `${BASE_PATH}/payment-processing/PaymentProcessing`,
  transcriptconfirm: `${BASE_PATH}/request-transcript-confirm/RequestTranscriptConfirm`,
  studymaterial: `${BASE_PATH}/order-study-material-flow/OrderStudyMaterialFlow`,
  studymaterialexams: `${BASE_PATH}/order-study-material-exams/OrderStudyMaterialExams`,
  studymaterialeguides: `${BASE_PATH}/order-study-material-eguides/OrderStudyMaterialEGuides`,
  studymaterialreview: `${BASE_PATH}/order-study-material-review/OrderStudyMaterialReview`,
  studymaterialterms: `${BASE_PATH}/order-study-material-terms/OrderStudyMaterialTerms`,
  studymaterialpayment: `${BASE_PATH}/payment-processing/PaymentProcessing`,
  studymaterialcomplete: `${BASE_PATH}/order-study-material-complete/OrderStudyMaterialComplete`,
  orderdetails: `${BASE_PATH}/order-details/OrderDetails`,
}

export const STUDENT_REGISTRATION_TITLE = 'CLEP Registration'
export const STUDENT_REGISTRATION_STEP_TITLES = [
  'Personal Information',
  'Select Your Exam',
  'Send Your Score',
  'Checkout',
]

export const STUDENT_REQUEST_TRANSCRIPT_STEP_TITLES = [
  'Transcript Ordering',
  'Checkout',
  'Terms and Conditions',
  'Checkout',
  'Confirmation',
]

export const STUDENT_ORDER_STUDY_MATERIAL_STEP_TITLES = [
  'Select Your E-Guide',
  'Select Your E-Guide',
  'Checkout',
  'Terms and Conditions',
  'Checkout',
  'Confirmation',
]

const getRegistrationFallback = ({ step }) => (
  <BannerFallback
    title={STUDENT_REGISTRATION_TITLE}
    subtitle={STUDENT_REGISTRATION_STEP_TITLES[step - 1]}
    columnSize={classnames(COLUMN_OFFSET, COLUMN_SIZE_FORMS)}
  />
)

export const STUDENT_REG_CREATE_FLOW = {
  profileintro: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.profileintro}`, step: 1 },
  profilereview: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.profilereview}`, step: 1 },
  profileeducation: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.profileeducation}`,
    step: 1,
  },
  profiledantes: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.profiledantes}`, step: 1 },
  profiledemographics: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.profiledemographics}`,
    step: 1,
  },
  examintro: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examintro}`, step: 2 },
  examterms: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examterms}`, step: 2 },
  examvoucher: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examvoucher}`, step: 2 },
  examselection: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examselection}`, step: 2 },
  examlocationselection: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examlocationselection}`,
    step: 2,
  },
  examaccommodationselection: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examaccommodationselection}`,
    step: 2,
  },
  examstudymaterialselection: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examstudymaterialselection}`,
    step: 2,
  },
  sendscoreintro: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.sendscoreintro}`,
    step: 3,
  },
  sendscoreselection: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.sendscoreselection}`,
    step: 3,
  },
  examaddanother: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.examaddanother}`,
    step: 3,
  },
  ordercheckoutintro: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.ordercheckoutintro}`,
    step: 4,
  },
  orderreview: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.orderreview}`, step: 4 },
  orderconfirm: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.orderconfirm}`, step: 4 },
  orderprocesspayment: {
    path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.orderprocesspayment}`,
    step: 4,
  },
  ordercomplete: { path: `/${STUDENT_BASE_LINKS.registrationcreate}/${STUDENT_BASE_LINKS.ordercomplete}`, step: 4 },
}

export const STUDENT_REG_UPDATE_FLOW = {
  examterms: { path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.examterms}` },
  examselection: { path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.examselection}` },
  examlocationselection: {
    path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.examlocationselection}`,
  },
  examaccommodationselection: {
    path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.examaccommodationselection}`,
  },
  examstudymaterialselection: {
    path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.examstudymaterialselection}`,
  },
  sendscoreselection: {
    path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.sendscoreselection}`,
  },
  orderreview: { path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.orderreview}` },
  orderprocesspayment: {
    path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.orderprocesspayment}`,
  },
  ordercomplete: { path: `/${STUDENT_BASE_LINKS.registrationupdate}/${STUDENT_BASE_LINKS.ordercomplete}` },
}

export const STUDENT_SENDSCORE_UPDATE_FLOW = {
  sendscoreselection: {
    path: `/${STUDENT_BASE_LINKS.registration}/${STUDENT_BASE_LINKS.sendscoreselection}`,
  },
}

export const STUDENT_ACCOMM_UPDATE_FLOW = {
  examaccommodationselection: {
    path: `/${STUDENT_BASE_LINKS.registration}/${STUDENT_BASE_LINKS.examaccommodationselection}`,
  },
}

export const STUDENT_REQUEST_TRANSCRIPT_FLOW = {
  transcriptselectscores: {
    path: `/${STUDENT_BASE_LINKS.transcriptcreate}/${STUDENT_BASE_LINKS.transcriptselectscores}`,
    step: 1,
  },
  transcriptcheckoutreview: {
    path: `/${STUDENT_BASE_LINKS.transcriptcreate}/${STUDENT_BASE_LINKS.transcriptcheckoutreview}`,
    step: 2,
  },
  transcriptterms: {
    path: `/${STUDENT_BASE_LINKS.transcriptcreate}/${STUDENT_BASE_LINKS.transcriptterms}`,
    step: 3,
  },
  transcriptpayment: {
    path: `/${STUDENT_BASE_LINKS.transcriptcreate}/${STUDENT_BASE_LINKS.transcriptpayment}`,
    step: 4,
  },
  transcriptconfirm: {
    path: `/${STUDENT_BASE_LINKS.transcriptcreate}/${STUDENT_BASE_LINKS.transcriptconfirm}`,
    step: 5,
  },
}

export const STUDENT_ORDER_STUDY_MATERIAL_FLOW = {
  studymaterialexams: {
    path: `/${STUDENT_BASE_LINKS.studymaterial}/${STUDENT_BASE_LINKS.studymaterialexams}`,
    step: 1,
  },
  studymaterialeguides: {
    path: `/${STUDENT_BASE_LINKS.studymaterial}/${STUDENT_BASE_LINKS.studymaterialeguides}`,
    step: 2,
  },
  studymaterialreview: {
    path: `/${STUDENT_BASE_LINKS.studymaterial}/${STUDENT_BASE_LINKS.studymaterialreview}`,
    step: 3,
  },
  studymaterialterms: {
    path: `/${STUDENT_BASE_LINKS.studymaterial}/${STUDENT_BASE_LINKS.studymaterialterms}`,
    step: 4,
  },
  studymaterialpayment: {
    path: `/${STUDENT_BASE_LINKS.studymaterial}/${STUDENT_BASE_LINKS.studymaterialpayment}`,
    step: 5,
  },
  studymaterialcomplete: {
    path: `/${STUDENT_BASE_LINKS.studymaterial}/${STUDENT_BASE_LINKS.studymaterialcomplete}`,
    step: 6,
  },
}

export const STUDENT_ROUTES = {
  dashboard: { path: `/${STUDENT_BASE_LINKS.dashboard}`, default: true },
  registrationcreate: {
    fallback: getRegistrationFallback,
    children: STUDENT_REG_CREATE_FLOW,
  },
  registrationdetails: { path: `/${STUDENT_BASE_LINKS.registrationdetails}` },

  transcriptcreate: {
    children: STUDENT_REQUEST_TRANSCRIPT_FLOW,
  },
  studymaterial: {
    children: STUDENT_ORDER_STUDY_MATERIAL_FLOW,
  },
  registrationcancelnoselection: {
    path: `${STUDENT_BASE_LINKS.registrationcancelnoselection}`,
  },
  registrationcancel: {
    path: `${STUDENT_BASE_LINKS.registrationcancel}`,
  },
  registrationcancelreminders: {
    path: `${STUDENT_BASE_LINKS.registrationcancelreminders}`,
  },
  registrationcancelreminderseguide: {
    path: `${STUDENT_BASE_LINKS.registrationcancelreminderseguide}`,
  },
  registrationcancelrevieworder: {
    path: `${STUDENT_BASE_LINKS.registrationcancelrevieworder}`,
  },
  registrationcancelconfirm: {
    path: `${STUDENT_BASE_LINKS.registrationcancelconfirm}`,
  },
  registrationupdate: {
    // fallback: getRegistrationFallback,
    children: STUDENT_REG_UPDATE_FLOW,
  },
  registrationaccommodationsupdate: {
    children: STUDENT_ACCOMM_UPDATE_FLOW,
  },
  registrationsendscoreupdate: {
    children: STUDENT_SENDSCORE_UPDATE_FLOW,
  },
  orderdetails: {
    path: `${STUDENT_BASE_LINKS.orderdetails}`,
  },
}

export const STUD_DASHBOARD_PANELS = {
  upcomingExams: 'upcoming-exams',
  scores: 'scores',
  orderHistory: 'order-history',
}

export const STUDENT_MAIN_NAVIGATION = [
  { path: STUDENT_ROUTES.dashboard.path, title: 'Home', icon: 'home', neverHideLink: true },
]

// non-DANTES: voucher page BEFORE exam selection page (default)
// DANTES: voucher page AFTER exam selection page (swapped)
// unskip Exam Location in case it has been set to be skipped
export const updateStepsWithDantesEligibility = (progress, isDantesEligible) => {
  if (progress?.length === 0) {
    return progress
  }
  const pathVoucher = STUDENT_REG_CREATE_FLOW.examvoucher.path
  const pathExam = STUDENT_REG_CREATE_FLOW.examselection.path
  const pathExamLocation = STUDENT_REG_CREATE_FLOW.examlocationselection.path
  const stepVoucherIndex = progress.findIndex((e) => e.path === pathVoucher)
  const stepExamIndex = progress.findIndex((e) => e.path === pathExam)

  if (
    (isDantesEligible && stepVoucherIndex < stepExamIndex) ||
    (!isDantesEligible && stepVoucherIndex > stepExamIndex)
  ) {
    return progress.map((e) => {
      if (e.path === pathVoucher) return progress[stepExamIndex]
      if (e.path === pathExam) return progress[stepVoucherIndex]
      if (e.path === pathExamLocation) return { ...e, skip: false }
      return e
    })
  }
  return progress
}
/**
 * end NAVIGATION
 */

export const ETHNCITY_FIELD_NOS = ['17']
export const RACE_FIELDS_NOS = ['0']

/**
 * FORMATTERS
 */
export const formatOrderPricingPayload = (orderForms) => {
  let allProducts = []

  orderForms.forEach((orderForm) => {
    const { examProductCd, voucherCode, remoteProctoringInd, isExamDantesFunded, studyMaterialProducts } = orderForm

    const products = [
      ...(examProductCd
        ? [
            {
              code: examProductCd,
              quantity: 1,
              ...(voucherCode && !isExamDantesFunded && { voucherId: voucherCode }),
              ...(remoteProctoringInd && { remoteProctoringInd }),
              ...(Boolean(isExamDantesFunded) && { dantesFundedInd: true }),
            },
          ]
        : []),
      ...(studyMaterialProducts || []).map((material) => ({
        code: material.productCd,
        quantity: 1,
        dantesFundedInd: material.isDantesEligible,
      })),
    ]

    allProducts = allProducts.concat(products)
  })

  return {
    products: allProducts,
  }
}

export const formatOrderPricingResponse = (pricingResponse, refProducts = [], key = PRICING_TYPE.PAYMENT) => {
  const pricesByExam = {}
  const refProductsMap = refProducts.reduce((result, item) => ({ ...result, [item.productCd]: item }), {})
  const pricingResProducts = pricingResponse[key].products || []

  pricingResProducts.forEach((product) => {
    const isRemoteProcotoring = product.lineItems.some((li) => li.code === PRODUCT_CODE_PROCTOR)
    const testLocation = isRemoteProcotoring ? 'Remote Proctoring' : 'Test Center'

    product.lineItems.forEach((lineItem) => {
      const { code, productType, originalCost, actualCost, dantesFundedInd, voucherId, refundAmount } = lineItem
      let examProductCd
      let title
      let subtitles
      let appliedText
      if (productType === PRODUCT_TYPE_EXAM) {
        examProductCd = product.code
        title = 'CLEP Exam Fee'
        subtitles = (subtitles || []).concat({
          text: `Original Test Location: ${testLocation}`,
          type: MESSAGE_TYPE.PRIMARY,
        })
      } else if (code === PRODUCT_CODE_PROCTOR) {
        examProductCd = product.code
        title = 'Remote Proctoring Service'
      } else if (productType === PRODUCT_TYPE_PDF) {
        const examProduct = refProducts.find((e) => e.relatedPdfProductCd === code)
        examProductCd = examProduct?.productCd
        title = `Examination Guide (PDF)`
      } else if (code === PRODUCT_CODE_TRANSCRIPT) {
        examProductCd = product.code
        title = 'CLEP Transcript Fee'
      }
      if (actualCost < originalCost) {
        if (voucherId) appliedText = 'Voucher code applied'
        if (dantesFundedInd) appliedText = 'DANTES funding applied'
      }
      if (key === PRICING_TYPE.REFUND && refundAmount === 0 && !voucherId && !dantesFundedInd) {
        subtitles = (subtitles || []).concat({
          text: 'This fee is not eligible for a refund',
          type: MESSAGE_TYPE.DANGER,
        })
      }

      const examProduct = refProductsMap[examProductCd]
      if (!pricesByExam[examProductCd]) {
        pricesByExam[examProductCd] = { examProductCd, examProductName: examProduct.productName, lineItems: [] }
      }
      pricesByExam[examProductCd].lineItems.push({
        productCd: code,
        title,
        subtitles,
        originalCost,
        actualCost,
        appliedText,
        refundAmount,
      })
    })
  })

  return {
    subtotal: pricingResponse[key].subtotal,
    tax: pricingResponse[key].tax,
    totalAmount: pricingResponse[key].totalAmount,
    examPrices: Object.values(pricesByExam),
  }
}

export const orderToCacheForm = (order, refProducts) => {
  const { products } = order
  return products
    .map((exam) => {
      const examProduct = refProducts.find((p) => p.productCd === exam.code)
      if (examProduct.productType !== PRODUCT_TYPE_EXAM) return undefined
      const relatedPdfProduct = products.find((eguide) => eguide.code === examProduct.relatedPdfProductCd)
      return {
        voucherCode: exam.voucherId,
        examProductCd: exam.code,
        isExamDantesFunded: !!exam.dantesFundedInd,
        remoteProctoringInd: !!exam.remoteProctoringInd,
        dantesEligibleInd: !!exam.dantesFundedInd,
        accommodations: exam.accommodations || [],
        diCodes: exam.diCodes || [],
        acceptedTerms: true,
        acceptedOrderTerms: undefined,
        ...(relatedPdfProduct
          ? {
              studyMaterialProducts: [
                { productCd: relatedPdfProduct.code, isDantesEligible: !!relatedPdfProduct.dantesFundedInd },
              ],
            }
          : {}),
      }
    })
    .filter(Boolean)
}

export const formatOrderPricingPayloadFromCancellationProducts = (orderId, cancellationsProducts) => {
  let allProducts = []

  cancellationsProducts.forEach((orderForm) => {
    const { examProductCd, voucherCode, remoteProctoringInd, isExamDantesFunded } = orderForm

    const products = [
      {
        code: examProductCd,
        quantity: 1,
        cancelInd: true,
        ...(voucherCode && !isExamDantesFunded && { voucherId: voucherCode }),
        ...(remoteProctoringInd && { remoteProctoringInd }),
        ...(Boolean(isExamDantesFunded) && { dantesFundedInd: true }),
      },
    ]

    allProducts = allProducts.concat(products)
  })

  return {
    orderId,
    products: allProducts,
  }
}

export const getProductExamName = (products, product) => {
  const match = products.find((p) => p.relatedPdfProductCd === product?.productCd)
  return match?.productName || product?.productName
}
